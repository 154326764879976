import React, { useReducer, useEffect } from 'react';
import { Button, Form } from 'semantic-ui-react'
import { Loader } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import insync from '../api/api_insync';

const initialState = {
    fields: {},
    sending: false,
    success: false
};

const reducer = (state, action) => {
    if (action.type == 'reset') {
        return initialState;
    }

    const result = { ...state };
    result[action.type] = action.value;
    return result;
}

const InputForm = (props) => {
    const { t, i18n } = useTranslation();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { fields, sending, success } = state;

    const addField = (field) => {
        fields[field.tag] = "";
        dispatch({ type: 'fields', value: fields });
    }

    const handleChange = (e, { name, value }) => {
        fields[name] = value;
        dispatch({ type: 'fields', value: fields });
    }

    const handleSubmit = () => {
        dispatch({ type: 'sending', value: true });

        const contactData = {
            ...fields,
            updatedAt: new Date().toISOString()
        };

        insync.sendContactMessage(
            contactData,
            (data) => {
                dispatch({ type: 'success', value: true });
                dispatch({ type: 'sending', value: false });
            },
            (error) => {
                // Handle error
                console.error('Error sending contact message:', error);
                dispatch({ type: 'sending', value: false });
                // Optionally, dispatch an error state
                // dispatch({ type: 'error', value: true });
            }
        );
    }
    
    const messageSuccess = () => {
        return (
            <div className="col-12">
                <div>
                    <p><b>{t(props.success.title)}</b></p>
                    <p>{t(props.success.description)}</p>
                    <a href="/">{t(props.success.return_home)}</a>
                </div>
                <br></br>
            </div>)
    }

    useEffect(() => {
        props.fields.map((field, index) => {
            addField(field)
        })
    }, [])

    const getField = (field) => {
        switch(field.type) {
            case 'text': 
            case 'email': {
                return (<Form.Input
                    key={field.key}
                    required
                    label={t(field.title)}
                    type={field.type}
                    onChange={handleChange}
                    name={field.tag}
                    value={fields[field.tag]} />)
            }
            case 'text_area': {
                return (<Form.TextArea
                    key={field.key}
                    required
                    label={t(field.title)}
                    onChange={handleChange}
                    name={field.tag}
                    value={fields[field.tag]} />)
            }
        }
    }

    return ((success && messageSuccess()) || <Form onSubmit={handleSubmit}>
        {props.fields.map((field, index) => {
            return getField(field)
        })}
        <Button type='submit' className='btn submit-btn' disabled={sending}>{t(props.submit)}</Button>
        {sending && <Loader style={{ marginLeft: 10, marginBottom: 10 }} active inline />}
    </Form>);
}

export default InputForm;
